@import './../../../../theme/theme';

.iconContainer {
  border-radius: 50%;
  position: absolute;
  top: 20px;
  right: 20px;
  height: 37px;
  width: 37px;
  background-color: #c5f097;
  cursor: pointer;
  .icon {
    margin-top: 10px;
    margin-left: 9px;
  }
}

.buttonContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  @include xsPhoneView {
    flex-wrap: wrap;
    justify-content: center;
    gap: 0px;
  }
}

.shareButtonContainer {
  display: flex;
  width: max-content;
  justify-content: center;
  @include xsPhoneView {
    width: 100%;
  }
}

.userDescription {
  @include xsPhoneView {
    padding-left: 15px;
    font-size: 12px;
    margin-top: 20px;
  }
}
.userInfoMainContainer {
  padding-bottom: 12px;
  @include xsPhoneView {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.userInfoContainer {
  display: flex;
  gap: 15px;
  align-items: center;
  position: relative;
  > .avatarRoot {
    width: 65px !important;
    height: 65px !important;
  }
  @include xsPhoneView {
    display: contents;
  }
  .userInfo {
    font-size: $fontXXLarge;
    font-weight: bold;
    @include xsPhoneView {
      font-size: 20px;
    }
  }
}

.socialPlatformOptionConatiner {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  position: relative;
  .Xicon {
    position: absolute;
    top: -78px;
    left: 192px;
    margin-top: 90px;
    > button {
      cursor: pointer;
    }
    @include xsPhoneView {
      top: -79px;
    }
  }
}

.skeletonContainer {
  padding-left: 200px;
  padding-right: 50px;
  padding-bottom: 20px;
}

.profileSkeleton {
  margin-top: 132px;
  height: 400px;
  padding-right: 100px;
  border-radius: 20px !important;
}

.customButton {
  font-size: 14px !important;
  height: 31px;
  padding: 6px 12px !important;
  border-radius: 24px;
  font-weight: 700;
}

.profileRedeemButton {
  @extend .customButton;
  width: 105px;
}

.registeredTreeButton {
  @extend .customButton;
}

.shareButton {
  @extend .customButton;
  width: 90px;
}

.supportButton {
  @extend .customButton;
  width: 106px;
}

.linkButton {
  @extend .customButton;
  width: 77px;
}

.publicProfileMainContainer {
  max-width: 1400px;
  padding-top: 20px;
  background-color: $backgroundBase;
  @include xsPhoneView {
    padding-left: 0px;
  }
}
.privateFeatureContainer,
.publicProfileContainer {
  display: flex;
  gap: 12px;
}
.profileContainer {
  margin-top: 80px;
  padding-top: 20px;
  @include xsPhoneView {
    margin-top: 140px;
    padding-top: 0px;
  }
}

.publicProfile {
  width: 100%;
  background: $backgroundBase;
  margin-top: 80px;

  &.impersonationMode {
    margin-top: 130px;
  }
}
